import Vue from 'vue';
import { reactive, toRefs, computed } from '@vue/composition-api';
import { useStore, useI18n } from '@/core/shared/compositions';
import { getLocationAnalytics, requestLocationAnalyticsExport } from './actions';
import { useChartJSOptions } from '@/core/shared/compositions/useChartJSOptions';

export function useLocationAnalytics (locationId) {
  const store = useStore();
  const i18n = useI18n();

  const location = computed(() => {
    return store.getters['entities/Location'](locationId);
  });

  const {
    colorArray,
    getRainbowDataset,
    getSolidColorDataset,
    getDefaultRadialChart,
    getDefaultBarChart,
    localizeData,
  } = useChartJSOptions(location.value.locale);

  const analytics = reactive({
    minutesPlayed: null,
    averageSessionLength: null,
    numberOfSessions: null,
    stationUtilization: null,
    dayOfWeekUsage: null,
    hourOfDayUsage: null,
    gameUsage: null,
  });

  function resetAnalytics () {
    Object.keys(analytics).forEach(key => {
      analytics[key] = null;
    });
  }

  function initVisualiztion (rawData, visualizationType) {
    if (visualizationType === 'pie') return getDefaultRadialChart(rawData.labels);
    if (visualizationType === 'bar') return getDefaultBarChart(rawData.labels);
  }

  function getDataset (rawData, visualizationType, index, dateRangesLength) {
    if (dateRangesLength === 1) {
      if (visualizationType === 'pie') return getRainbowDataset(rawData.data);
      if (visualizationType === 'bar') return getSolidColorDataset(rawData.data);
    } else {
      return getSolidColorDataset(rawData.data, index);
    }
  }

  function addToAnalytic (rawData, analyticName, visualizationType, index, dateRangesLength) {
    if (!rawData) return;

    if (!analytics[analyticName]) {
      analytics[analyticName] = initVisualiztion(rawData, visualizationType);
    }

    const newAnalytic = { ...analytics[analyticName] };
    newAnalytic.data.labels = rawData.labels;
    newAnalytic.data.datasets[index] = getDataset(rawData, visualizationType, index, dateRangesLength);
    analytics[analyticName] = newAnalytic;
  }

  function onDataRecieved ({ locationAnalytics }, done, index, dateRangesLength) {
    Object.keys(locationAnalytics).forEach(analyticName => {
      const rawData = locationAnalytics[analyticName];
      const localizedData = localizeData(rawData);

      if (
        analyticName === 'stationUtilization' ||
        analyticName === 'gameUsage'
      ) {
        addToAnalytic(localizedData, analyticName, 'pie', index, dateRangesLength);
      } else if (
        analyticName === 'dayOfWeekUsage' ||
        analyticName === 'hourOfDayUsage'
      ) {
        addToAnalytic(localizedData, analyticName, 'bar', index, dateRangesLength);
      } else {
        if (!analytics[analyticName]) analytics[analyticName] = [];
        Vue.set(analytics[analyticName], index, localizedData);
      }
    });

    if (done) store.commit('setLoading', false);
  }

  async function doGetLocationAnalytics (dateRanges) {
    store.commit('setLoading', true);

    resetAnalytics();

    return Promise.all(dateRanges.map((dateRange, index) => {
      return getLocationAnalytics(
        { id: locationId },
        dateRange.startTime,
        dateRange.endTime,
        (data, done) => onDataRecieved(data, done, index, dateRanges.length),
      );
    }));
  }

  async function doRequestLocationAnalyticsExport (dateRanges, type) {
    store.commit('setLoading', true);
    await Promise.all(dateRanges.map(dateRange => {
      return requestLocationAnalyticsExport(
        { id: locationId },
        dateRange.startTime,
        dateRange.endTime,
        type,
      );
    }));
    store.commit('setLoading', false);
    store.commit('setFlash', {
      type: 'success',
      message: i18n.t('Success, when the export is ready it will be sent to your email.'),
    });
  }

  const tooltips = computed(() => ({
    minutesPlayed: i18n.t('Total minutes played on {locationName} stations within the chosen date range.', { locationName: location.value.title }),
    averageSessionLength: i18n.t('Average game session length in minutes on {locationName} stations within the chosen date range. A game session represents a launch and then an exit of a piece of content.', { locationName: location.value.title }),
    numberOfSessions: i18n.t('Total game sessions on {locationName} stations within the chosen date range. A game session represents a launch and then an exit of a piece of content.', { locationName: location.value.title }),
    dayOfWeekUsage: i18n.t('Total minutes on {locationName} stations within the chosen date range grouped by day of week.', { locationName: location.value.title }),
    hourOfDayUsage: i18n.t('Total minutes on {locationName} stations within the chosen date range grouped by hour.', { locationName: location.value.title }),
    stationUtilization: i18n.t('Total minutes played at {locationName} within the chosen date range grouped by station.', { locationName: location.value.title }),
    gameUsage: i18n.t('Total minutes played at {locationName} within the chosen date range groups by the top 10 most played content.', { locationName: location.value.title }),
  }));

  return {
    colorArray,
    doGetLocationAnalytics,
    doRequestLocationAnalyticsExport,
    ...toRefs(analytics),
    tooltips,
  };
}
