import { gql } from '@/core/shared/api';

export async function getLocationAnalytics (location, startTime, endTime, callback) {
  const query = `query locationAnalytics ($location: LocationInput, $startTime: String, $endTime: String) {
    locationAnalytics (location: $location, startTime: $startTime, endTime: $endTime) {
      minutesPlayed
      averageSessionLength
      numberOfSessions
      stationUtilization @defer {
        labels
        data
      }
      dayOfWeekUsage @defer {
        labels
        data
      }
      hourOfDayUsage @defer {
        labels
        data
      }
      gameUsage @defer {
        labels
        data
      }
    }
  }`;

  await gql.requestWithDefer(query, {
    location,
    startTime,
    endTime,
  }, {}, callback);
}

export async function requestLocationAnalyticsExport (location, startTime, endTime, type) {
  const query = `mutation requestAnalyticsExport(
    $location: LocationInput
    $startTime: String
    $endTime: String
    $type: String
  ) {
    requestAnalyticsExport(
      location: $location
      startTime: $startTime
      endTime: $endTime
      type: $type
    )
  }`;

  return gql.request(query, {
    location,
    startTime,
    endTime,
    type,
  });
}
